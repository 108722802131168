import { Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { VIOLETA_OSCURO } from '../../colors/colores';

const Titulo = ({ children }) => {
  const lgScreen = useMediaQuery('(min-width:600px)');

  return (
    <Typography
      variant={lgScreen ? 'h1' : 'h2'}
      gutterBottom
      component='div'
      align='center'
      sx={{ textTransform: 'lowercase', color: VIOLETA_OSCURO }}
    >
      {children}
    </Typography>
  );
};

export default Titulo;
