import React from "react";
import { IconButton, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faYoutube,
  faInstagram,
  faApple,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { VIOLETA_OSCURO } from "../colors/colores";

const redes = [
  {
    href: "https://open.spotify.com/artist/0AgV5f8reGCw563caRiW6x",
    icon: faSpotify,
  },
  {
    href: "https://www.youtube.com/c/ChukaHurtado",
    icon: faYoutube,
  },
  {
    href: "https://www.instagram.com/chukahurtado/",
    icon: faInstagram,
  },
  {
    href: "https://music.apple.com/co/artist/chuka-hurtado/1455257992",
    icon: faApple,
  },
  {
    href: "https://www.tiktok.com/@chukahurtado",
    icon: faTiktok,
  }
];

const RedesSociales = () => {
  return (
    <Stack direction="row" spacing={2}>
      {redes?.map((red, index) => {
        return (
          <IconButton
            key={index}
            target="_blank"
            href={red.href}
            style={{ color: `${VIOLETA_OSCURO}`, fontSize: "40px" }}
          >
            <FontAwesomeIcon icon={red.icon} />
          </IconButton>
        );
      })}
    </Stack>
  );
};

export default RedesSociales;
