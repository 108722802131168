import { Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { VIOLETA_OSCURO } from '../../colors/colores';
import RedesSociales from '../../components/RedesSociales';
import fondoLg from '../../images/portadaGuitarraRecortada.jpg';
import fondoMobile from '../../images/portadaGuitarraVertical.JPG';
import './Portada.css';

const Portada = () => {
  const lgScreen = useMediaQuery('(min-width:600px)');
  const backgroundImage = lgScreen ? fondoLg : fondoMobile;

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: `${lgScreen ? '50rem' : '40rem'}`,
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: `${lgScreen ? '4rem' : '1rem'}`,
          left: `${lgScreen ? '9rem' : '2rem'}`,
        }}
      >
        <Typography
          id='titulo'
          variant='h1'
          component='div'
          color={VIOLETA_OSCURO}
          gutterBottom
          style={{ fontSize: '130px' }}
        >
          chuka <br /> hurtado
        </Typography>
        <div>
          {lgScreen && <RedesSociales id='redesSociales'></RedesSociales>}
        </div>
      </div>
    </div>
  );
};

export default Portada;
