import React from 'react';
import { Container, Typography, useMediaQuery } from '@mui/material';
import fondoLg from '../../images/bioHorizontal.jpg';
import fondoMobile from '../../images/bioVertical.jpg';
import './Bio.css';
import Titulo from '../../components/common/Titulo';
import { BIO } from '../../constants/titulos';
import { BIO_PARRAFO } from '../../constants/parrafos';
import { VIOLETA_OSCURO } from '../../colors/colores';

const Bio = () => {
  const lgScreen = useMediaQuery('(min-width:600px)');
  const backgroundImage = lgScreen ? fondoLg : fondoMobile;

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        height: '50rem',
      }}
    >
      <Container id='containerBio' style={{ padding: '2rem 10rem 30rem' }}>
        <Titulo>{BIO}</Titulo>
        <Typography variant='body1' align='center' color={VIOLETA_OSCURO} gutterBottom>
          {BIO_PARRAFO}
        </Typography>
      </Container>
    </div>
  );
};

export default Bio;
