import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import portadaAmorLibra from '../LinkTree/img/portadaAmorLibra.png';
import portadaAlgunasNoches from '../LinkTree/img/portadaAlgunasNoches.PNG';
import portadaAquellosMomentos from '../LinkTree/img/portadaAquellosMomentos.png';
import portadaDepartamento from '../LinkTree/img/portadaDepartamento.jpg';
import portadaElQueSoniabaEraYo from '../LinkTree/img/portadaElQueSoniabaEraYo.jpeg';
import fondo from '../../images/pared.png';
import Enlaces from './Enlaces';
import './LinkTree.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Titulo from '../../components/common/Titulo';
import { LINK_TREE } from '../../constants/titulos';

const portadas = [
  {
    src: portadaAmorLibra,
    alt: 'Amor Libra',
  },
  {
    src: portadaElQueSoniabaEraYo,
    alt: 'El que soñaba era yo',
  },
  {
    src: portadaDepartamento,
    alt: 'Departamento',
  },
  {
    src: portadaAlgunasNoches,
    alt: 'Algunas Noches',
  },
  {
    src: portadaAquellosMomentos,
    alt: 'Aquellos Momentos',
  },
];

const LinkTree = () => {
  const lgScreen = useMediaQuery('(min-width:600px)');

  return (
    <Grid
      id='containerLinkTree'
      container
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: '100px 0',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
        <Splide
          options={{
            type: 'loop',
            autoplay: true,
            arrows: false,
          }}
        >
          {portadas?.map((portada, index) => {
            return (
              <SplideSlide>
                <img
                  key={index}
                  src={portada.src}
                  alt={portada.alt}
                  style={{
                    width: '70%',
                    boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.75)',
                  }}
                />
              </SplideSlide>
            );
          })}
        </Splide>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div style={{ marginTop: `${!lgScreen && '2rem'}` }}>
          <Titulo>{LINK_TREE}</Titulo>
          <Enlaces></Enlaces>
        </div>
      </Grid>
    </Grid>
  );
};

export default LinkTree;
