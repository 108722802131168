import React from "react";
import {
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faYoutube,
  faAmazon,
  faApple,
  faDeezer,
} from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import "./LinkTree.css";

const enlaces = [
  {
    href: "https://links.altafonte.com/5vlnmjb",
    icon: faExternalLinkAlt,
    nombre: "Amor Libra",
  },
  {
    href: "https://open.spotify.com/artist/0AgV5f8reGCw563caRiW6x",
    icon: faSpotify,
    nombre: "Spotify",
  },
  {
    href: "https://music.youtube.com/channel/UC99ZJsmerzTwFw5-3sA1qMw",
    icon: faYoutube,
    nombre: "YouTube Music",
  },
  {
    href: "https://music.apple.com/co/artist/chuka-hurtado/1455257992",
    icon: faApple,
    nombre: "Apple Music",
  },
  {
    href: "https://music.amazon.com/artists/B07PDJX36J/chuka-hurtado",
    icon: faAmazon,
    nombre: "Amazon Music",
  },
  {
    href: "https://www.deezer.com/us/artist/61012442",
    icon: faDeezer,
    nombre: "Deezer",
  },
];

const Enlaces = () => {
  return (
    <List style={{ fontSize: "25px" }}>
      {enlaces?.map((enlace, index) => {
        return (
          <ListItemButton
            key={index}
            component={Button}
            target="_blank"
            href={enlace.href}
            className="containerEnlaceLinkTree"
            sx={{ pl: 4 }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                style={{ color: "black", fontSize: "30px" }}
                icon={enlace.icon}
              />
            </ListItemIcon>
            <ListItemText primary={enlace.nombre}></ListItemText>
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default Enlaces;
