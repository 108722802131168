import "./App.css";
import Bio from "./pages/Bio/Bio";
import Footer from "./pages/Footer/Footer";
import LinkTree from "./pages/LinkTree/LinkTree";
import Portada from "./pages/Portada/Portada";
// import Presentaciones from "./pages/Presentaciones/Presentaciones";
import Videos from "./pages/Videos/Videos";
import ReactGA from "react-ga";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-W53R0X1T9L");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div style={{ backgroundColor: "black" }}>
      <Portada></Portada>
      <LinkTree></LinkTree>
      <Bio></Bio>
      <Videos></Videos>
      {/* <Presentaciones></Presentaciones> */}
      <Footer></Footer>
    </div>
  );
}

export default App;
