import React from 'react';
import { Container, Grid } from '@mui/material';
import './Videos.css';
import Titulo from '../../components/common/Titulo';
import { VIDEOS } from '../../constants/titulos';

const videos = [
  {
    src: 'https://www.youtube.com/embed/xgFvmBLcLAA', 
  },
  {
    src: 'https://www.youtube.com/embed/lAZXASzK4z0',
  },
  {
    src: 'https://www.youtube.com/embed/HGCMC5KO5cg',
  },
  {
    src: 'https://www.youtube.com/embed/vRj2yXJCLqg',
  },
];

const Videos = () => {
  return (
    <div
      style={{ color: 'white', backgroundColor: 'black', padding: '50px 0' }}
    >
      <Container>
        <Titulo>{VIDEOS}</Titulo>
        <Grid container spacing={2} style={{ textAlign: 'center' }}>
          {videos?.map((video, index) => {
            return (
              <Grid key={index} item xs={12} md={6}>
                <iframe
                  className='videos'
                  width='560'
                  height='315'
                  src={video.src}
                  title='YouTube video player'
                  frameborder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen
                ></iframe>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default Videos;
