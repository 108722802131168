import React from "react";
import { Typography } from "@mui/material";
import RedesSociales from "../../components/RedesSociales";
import "./Footer.css";
import { FOOTER } from "../../constants/titulos";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 0",
        textAlign: "center",
      }}
    >
      <Typography align="center" variant="h4" gutterBottom component="div">
        {FOOTER}
      </Typography>
      <div id="redesSocialesFooter">
        <RedesSociales></RedesSociales>
      </div>
      <Typography
        style={{ marginTop: "20px" }}
        variant="caption"
        display="block"
        gutterBottom
      >
        &copy; Todos los derechos reservados
      </Typography>
    </div>
  );
};

export default Footer;
